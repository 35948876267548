import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import Features from "../components/Features/Features"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
// import PerksModule from "../components/PerksModule/PerksModule"
// import Perk from "../components/PerksModule/Perk"
// import LatestPosts from "../components/Post/LatestPosts"

const Index = () => {
  const siteMeta = useSiteMetadata()
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Why choose WOOD "
          titleEnd={"?"}
          subTitle={`Wood has a long history and Humans are using wood by too many centuries. The most common thing that we all are using wood in our daily lifestyle. From baby toys to building material we use wood.

          Wood play’s significant role in our life and support the nature as well. Wood is part of our life and whenever we invest in wood that means we invest in our life and we get more closer to nature and feel the nature.          `}
          link="#section_why_wood"
          showLearnMore
        />
        <BasicTextModule
          id="section_why_wood"
          title="Why choosing WOOD is the best option?"
          listItems={[
            "Wood is the most sustainable and durable material.",
            "Wood is natural and eco-friendly.",
            "Wood is hygienic and affordable.",
            "Wood is resistant and elastic.",
            "Wood is a renewable, reusable and recyclable resource.",
            "Wood is a good material from a macroeconomic perspective.",
            "Wood is biodegradable.",
          ]}
          link="/products"
          linkText="View Products"
        />
        {/* <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule> */}
        <Features
          title={`Featured Products from ${siteMeta.title}.`}
          introduction={`Discover the essence of craftsmanship and natural beauty with our handpicked selection of featured products from ${siteMeta.title}.`}
        />
        {/* <LatestPosts
          title="The Latest from Barcadia"
          introduction="Cras scelerisque, tellus sed gravida tincidunt, velit tellus blandit justo, nec viverra augue erat in erat. Maecenas iaculis sed purus non fringilla."
        /> */}
      </Layout>
    </>
  )
}

export default Index
