import * as React from "react"
import { Link } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../Button/Button"

const BannerModule = ({
  children,
  title,
  titleEnd,
  subTitle,
  price,
  enquire,
  showLearnMore,
}) => {
  function scrollToArea() {
    const sectionToScroll = window.document.getElementById("topContent")
    sectionToScroll.scrollIntoView({
      behavior: "smooth",
      block: "end",
    })
  }

  return (
    <>
      <BannerModuleStyles>
        {children ? (
          children
        ) : (
          <StaticImage
            className="banner__image"
            imgClassName="banner__image--content"
            src="../../../static/wood-stock-building.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
        )}

        <div className="container">
          <div className="banner__content">
            {title && (
              <h1>
                {title}
                <span style={{ color: "var(--primary)" }}>
                  {titleEnd ?? "."}
                </span>
              </h1>
            )}
            {subTitle && <h2>{subTitle}</h2>}
            {/* {price && (
              <h2 className="price">
                £{price}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h2>
            )} */}
            {showLearnMore && (
              <div className="banner__btns">
                {enquire && (
                  <Button
                    className="btn"
                    text="Enquire Now"
                    as={Link}
                    to="/contact"
                  />
                )}
                <Button onClick={scrollToArea} text="Learn More" />
              </div>
            )}
          </div>
        </div>
        <div className="gradient"></div>
      </BannerModuleStyles>
      <span id="topContent"></span>
    </>
  )
}

export default BannerModule
