import * as React from "react"
import { ContactStyles } from "./ContactStyles"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"

const Contact = () => {
  const { email } = useSiteMetadata()

  return (
    <ContactStyles className="section">
      <p>
        Send us an email at{" "}
        {email.map((item, index) => {
          return (
            <>
              <a href={`mailto:${item}`} key={index}>
                {item}
              </a>
              {index !== email.length - 1 && ", "}
            </>
          )
        })}
      </p>
    </ContactStyles>
  )
}

export default Contact
